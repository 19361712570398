import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Button } from 'Src/components/Button/Button';
import { Loader } from 'Src/components/Loader/Loader';
import { AppUser } from 'Src/views/App/App';
import { readStudentsFromCSVFile, pullFormsHouses } from 'Src/helpers/utils/csvUtils';
import { uploadStudents, deleteAllStudents } from 'Src/helpers/service/admin/students';
import { deleteForm, getAllForms } from 'Src/helpers/service/admin/forms';
import { deleteHouse, getAllHouses } from 'Src/helpers/service/admin/houses';
import { getSchoolStudentsCount } from 'Src/helpers/service/admin/students';
import { ImportError } from 'Src/helpers/utils/types';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';

interface ImportStudentsProps {
  user: AppUser;
  school: any; // Adjust the type to match your school object structure
}

export const ImportStudents: React.FC<ImportStudentsProps> = ({ user, school }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<ImportError[]>([]);
  const [studentsToUpload, setStudentsToUpload] = useState<any[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  useEffect(() => {
    if (!school) {
      console.error('School data is missing or incomplete.');
    } else {
      console.log('ImportStudents component received full school data:', school);
    }
  }, [school]);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (!selectedFile) return;

    setFile(selectedFile);
    setIsLoading(true);
    try {
      const result = await readStudentsFromCSVFile(selectedFile);
      setErrors(result.errors || []);
      setStudentsToUpload(result.students || []);

      if (result.errors.length > 0 && fileInputRef.current) {
        fileInputRef.current.value = '';
        setFile(null);
      }
    } catch (err) {
      setErrors([{ type: 'FileReadError', code: 'FILE_READ_ERROR', message: err.message }]);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      setFile(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadClick = async () => {
    if (!file) {
      alert('Please select a file first.');
      return;
    }

    if (!school || !school.forms || !school.houses) {
      alert('School data is missing or incomplete.');
      return;
    }

    setIsLoading(true);

    try {
      // Delete all existing students
      await deleteAllStudents(user);

      const result = await readStudentsFromCSVFile(file);
      const processedResult = await pullFormsHouses(result, school, user);
      await uploadStudents(user, processedResult.students);

      // Clear empty forms
      const forms = await getAllForms(user);
      const deleteFormPromises = forms.map(async form => {
        const studentCount = await getSchoolStudentsCount(user, { formId: form.id });
        if (studentCount.count === 0) {
          console.log(`Deleting empty form: ${form.name}`);
          return deleteForm(user, form.id);
        }
      });

      await Promise.all(deleteFormPromises);

      // Clear empty houses
      const houses = await getAllHouses(user);
      const deleteHousePromises = houses.map(async house => {
        const studentCount = await getSchoolStudentsCount(user, { houseId: house.id });
        if (studentCount.count === 0) {
          console.log(`Deleting empty house: ${house.name}`);
          return deleteHouse(user, house.id);
        }
      });

      await Promise.all(deleteHousePromises);

      // Clear the form and reset after successful upload
      setStudentsToUpload([]);
      setFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      // Show final success message in a modal
      setModalMessage('Students have been successfully uploaded.');
      setIsSuccessModalOpen(true);
    } catch (error) {
      console.error('Error during student import', error);
      setErrors(prevErrors => [...prevErrors, { type: 'UploadError', code: 'UPLOAD_ERROR', message: error.message }]);
      alert('Some students failed to upload. Please check the errors.');
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setIsSuccessModalOpen(false);
  };

  if (school && school.studentImportForAdminAllowed === false) {
    return (
      <div className="bForm">
        <h3>Import Students</h3>
        <p>
          Student import is disabled on your school account. Please get in touch with our support team if you would like
          to discuss this.
        </p>
      </div>
    );
  }

  return (
    <div className="bForm">
      <h3>Import Students</h3>
      <p>
        Download the <a href="/path/to/example.csv">CSV template</a> and fill it out with the student data. Once
        completed, select the file below and click upload.
      </p>
      <div className="eForm_field">
        <input type="file" name="file" onChange={handleFileChange} ref={fileInputRef} />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {errors.length > 0 && (
            <div>
              <p style={{ color: 'red', fontWeight: 'bold' }}>
                Your file has some errors. Please correct them and select an updated version:
              </p>
              <ul>
                {errors.map((error: ImportError, idx: number) => (
                  <li key={idx} style={{ color: 'red', fontWeight: 'bold' }}>
                    {error.message}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {studentsToUpload.length > 0 && (
            <div>
              <p>Students to upload: {studentsToUpload.length}</p>
              <Button onClick={handleUploadClick} text="Upload Students" />
            </div>
          )}
        </>
      )}

      {/* Success Modal */}
      {isSuccessModalOpen && (
        <SimpleModal
          isOpen={isSuccessModalOpen}
          title={'Success'}
          body={modalMessage}
          buttonText={'Close'}
          onButtonClick={closeModal}
        />
      )}
    </div>
  );
};
