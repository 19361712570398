import axios from 'axios';
import { AppUser } from 'Src/views/App/App';
import { DEFAULT_FILTER } from './user';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Count } from '../../../models/count';

interface StudentData {
  firstName: string;
  lastName: string;
  gender: string;
  birthday?: string;
  formId?: string;
  houseId?: string;
  medicalInfo?: string;
  nextOfKin?: any[];
  misId?: string;
}

/** STUDENTS TABLE */
export function getSchoolStudents(user: AppUser, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/students`, config).then(response => {
    return response.data;
  });
}

export function getAllSchoolStudents(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/students`, config).then(response => {
    return response.data;
  });
}

export function getSchoolStudentsCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where
        }
      }
    };
  } else {
    config = {
      headers: { usid: key }
    };
  }

  return axios.get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/students/count`, config).then(response => {
    return response.data;
  });
}

export function addSchoolStudent(user: AppUser, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.post(`${window.apiBase}/i/schools/${activeSchoolId}/students`, data, config).then(response => {
    return response.data;
  });
}

export function deleteStudent(user: AppUser, studentId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios.delete(`${window.apiBase}/i/schools/${activeSchoolId}/students/${studentId}`, config).then(response => {
    return response.data;
  });
}

export async function deleteAllStudents(user: AppUser) {
  const allStudents = await getAllSchoolStudents(user);

  await Promise.all(allStudents.map(student => deleteStudent(user, student.id)));
}

export function updateSchoolStudent(user: AppUser, studentId, data) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/students/${studentId}`, data, config)
    .then(response => {
      return response.data;
    });
}

export function assignUserTagByStudentId(user: AppUser, data, studentId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/students/${studentId}/userTags`, data, config)
    .then(response => {
      return response.data;
    });
}

export function deleteUserTagByStudentId(user: AppUser, studentId, userTagId) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .delete(`${window.apiBase}/i/schools/${activeSchoolId}/students/${studentId}/userTags/${userTagId}`, config)
    .then(response => {
      return response.data;
    });
}

export function mergeStudent(user: AppUser, studentId, data: { permissionId: string; userId: string }) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/students/${studentId}/merge`, data, config)
    .then(response => {
      return response.data;
    });
}

export async function uploadStudents(user: AppUser, students: StudentData[]): Promise<void> {
  for (const student of students) {
    try {
      await addSchoolStudent(user, student);
    } catch (error) {
      console.error(`Failed to upload student ${student.firstName} ${student.lastName}:`, error);
      throw error;
    }
  }
}
